import React, { useContext, useState, useMemo } from "react"
import styled from "styled-components"
import Button from "../ProductSingle/Button"
import BranchContext from "../../context/BranchContext"
import CartContext from "../../context/CartContext"
import { formatPrice } from "../../lib/util.jsx"
import { stockFetch } from "./stockfetch"

// ======================
// 	🧱🧱 COMPONENT 🧱🧱
// ======================
const Table = ({ headings, values }) => {
  const [buttonLoading, setButtonLoading] = useState(false)
  const [message, setMessage] = useState("")
  const { addToCart, setCartDrawerOpen } = useContext(CartContext)
  const { selectedBranch } = useContext(BranchContext)

  // Complicated code that fetches the stock and updates the structure
  const preparedValues = stockFetch(values, selectedBranch)

  return (
    <>
      {!selectedBranch && (
        <ErrorBox>No branch selected, please select a store location</ErrorBox>
      )}
      {message && <ErrorBox>{message}</ErrorBox>}
      <TableWrap>
        <thead>
          <tr>
            {headings.map((heading, i) => (
              <th className="no-wrap" key={i}>
                {heading}
              </th>
            ))}
            <th className="narrow"></th>
          </tr>
        </thead>
        <tbody>
          {preparedValues.map((value, i) => {
            return (
              <tr key={i}>
                <td dangerouslySetInnerHTML={{ __html: value.title || "-" }} />
                <td
                  className="no-wrap"
                  dangerouslySetInnerHTML={{ __html: value.size || "-" }}
                />
                <td
                  className="no-wrap"
                  dangerouslySetInnerHTML={{ __html: value.sku || "-" }}
                />
                <StockEntry
                  className={value.avail === "--" ? "blink" : ""}
                  dangerouslySetInnerHTML={{ __html: value.soh || 0 }}
                />
                <StockEntry
                  className={value.avail === "--" ? "blink" : ""}
                  dangerouslySetInnerHTML={{ __html: value.avail || 0 }}
                />
                <td className="no-wrap">{formatPrice(value.price) || "-"}</td>
                <td>
                  <Button
                    label="ADD"
                    loading={buttonLoading}
                    disabled={buttonLoading}
                    loaderSize="16px"
                    onClick={() => {
                      setMessage("")
                      setButtonLoading(true)
                      addToCart({ product_id: value.databaseId })
                        .then(() => {
                          setCartDrawerOpen(true)
                          setButtonLoading(false)
                        })
                        .catch((error) => {
                          console.log(error)
                          if (error && error.message) {
                            setMessage(error.message)
                          } else {
                            setMessage(
                              "Something went wrong, product could not be added to cart"
                            )
                          }
                          setButtonLoading(false)
                        })
                    }}
                  />
                </td>
              </tr>
            )
          })}
        </tbody>
      </TableWrap>
    </>
  )
}

// ======================
// 	💅🏼💅🏼 STYLES 💅🏼💅🏼
// ======================
const StockEntry = styled.td`
  &.blink {
    @keyframes blink {
      50% {
        opacity: 0;
      }
    }
    animation: blink 0.3s step-start 0s infinite;
  }
`
const ErrorBox = styled.div`
  margin: 0 auto 10px auto;
  text-align: center;
  padding: 15px 20px;
  background: pink;
  border-color: red;
  border-style: solid;
  border-width: 2px;
  border-radius: 30px;
  a {
    color: #0988bb;
    text-decoration: underline;
  }
`
const TableWrap = styled.table`
  font-size: 12px;
  text-align: left;
  background: white;
  box-shadow: 0 13px 27px 0 rgba(97, 97, 97, 0.25);
  border-radius: 4px;
  width: 100%;
  max-width: 1280px;
  padding: 20px;
  margin: 0 auto 40px auto;
  tr {
    border: 1px dashed #dee3e8;
    &:hover {
      background-color: #9ce2fc;
    }
  }

  td {
    font-size: 18px;
    padding: 2px 10px;
    button {
      width: 80px;
      height: 24px;
      margin: auto;
      div {
        margin: auto;
      }
    }

    &.no-wrap {
      white-space: nowrap;
    }
  }

  th {
    background-color: #223247;
    color: white;
    font-size: 25px;
    padding: 10px;
    /* .narrow {
      max-width: 100%;
    } */

    &.no-wrap {
      white-space: nowrap;
    }
  }
  /*Mobile View*/
  @media only screen and (max-width: 760px) {
    td,
    tr {
      display: block;
    }

    thead tr {
      position: absolute;
      top: -9999px;
      left: -9999px;
    }

    tr {
      padding: 20px;
      > td:nth-of-type(1) {
        font-weight: bold;
      }
    }

    td {
      padding: 0;
      border: none;
      position: relative;
      text-align: left;
    }
  }
`

export default Table
