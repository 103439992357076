import React, { useState, useMemo, useEffect, useRef } from "react"
import { requestFlatlistStock } from "../../lib/woocommApi"

// Show the value if it's a number, otherwise show the string, otherwise show a dash
const formatStockLevel = (value) => {
  const intValue = parseInt(value)
  if (!isNaN(intValue)) {
    return `${intValue}`
  }
  return value || "-"
}

// Hook that fetches stock and stuff
export const stockFetch = (values, selectedBranch) => {
  // Default to the passed values
  const [currentStock, setCurrentStock] = useState({})
  const [stockStatus, setStockStatus] = useState("ready")

  let stockTimeout = useRef()

  // Fetch stock function
  const fetchStock = async () => {
    if (selectedBranch && selectedBranch !== "") {
      setStockStatus("busy")
      const skus = values.map((val) => val.sku)
      const stockResponse = await requestFlatlistStock(skus, selectedBranch)

      // Set fetched stock
      setCurrentStock((prStock) => ({
        ...prStock,
        ...stockResponse,
      }))

      setStockStatus("done")
    } else {
      console.log("Cannot query flatlist stock, no branch selected")
    }
  }

  // If the list of products change, fetch the stock again if it's not busy fetching
  // This acts as a buffer for when the user enters search terms so we don't fetch and fetch and fetch
  useEffect(() => {
    // If the timer is already running, cancel it
    if (stockStatus === "waiting") {
      clearTimeout(stockTimeout.current)
    }

    // Start a new timer to fetch the stock levels
    stockTimeout.current = setTimeout(fetchStock, 2000)
    setStockStatus("waiting")
    return () => clearTimeout(stockTimeout.current)
  }, [values])

  // return prepared values
  return Array.isArray(values) && values.length > 0
    ? values.map((product) => {
        // If stockfetch is busy, show blinky
        if (stockStatus !== "done") {
          return {
            ...product,
            soh: "--",
            avail: "--",
          }
        }

        // Format stock values if available
        product.soh = formatStockLevel(currentStock[product.sku]?.soh)
        product.eff = formatStockLevel(currentStock[product.sku]?.effective)

        // Math to determine values to show
        if (!isNaN(product.soh)) {
          product.soh = product.soh < 0 ? 0 : product.soh
        }
        if (!isNaN(product.eff)) {
          product.eff = product.eff < 0 ? 0 : product.eff
        }

        // Populate avail only if the stock values are valid
        if (!isNaN(product.soh) && !isNaN(product.eff)) {
          product.avail = Math.min(product.eff, product.soh)
        } else {
          product.avail = "-"
        }

        return product
      })
    : []
}
