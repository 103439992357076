import React, { useState, useContext, useMemo } from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"
import BranchContext from "../../context/BranchContext"
import Button from "../ProductSingle/Button"
import Table from "./table"
import { slugify } from "../../lib/stringHandling"
import { isInStoreFrontend } from "../../services/auth"

const entriesPerPage = 50

// ======================
// 	🧱🧱 COMPONENT 🧱🧱
// ======================
const Flatlist = ({ location }) => {
  let graphqlData = {}
  if (isInStoreFrontend()) {
    graphqlData = useStaticQuery(FLATLIST_PRODUCTS)
  }
  const flatlistProducts =
    (graphqlData.allProductFlatlist && graphqlData.allProductFlatlist.nodes) ||
    []

  const { selectedBranch } = useContext(BranchContext)
  const [pagesLoaded, setPagesLoaded] = useState(1)
  const [searchTerm, setSearchTerm] = useState("")
  const [searchResults, setSearchResults] = useState(flatlistProducts)

  // Handle a changing search term
  const handleChange = (event) => {
    setSearchTerm(event.target.value)
    if (event.target.value != "") {
      const results = flatlistProducts.filter(
        (item) =>
          slugify(item.title).includes(slugify(event.target.value)) ||
          slugify(item.sku).includes(slugify(event.target.value))
      )
      setSearchResults(results)
    } else {
      setSearchResults(flatlistProducts)
    }
  }

  // Only show first page of results
  const resultsToRender = useMemo(
    () =>
      searchResults.slice(0, pagesLoaded * entriesPerPage).map((product) => {
        // Ignore stock levels if no branch selected
        if (!selectedBranch) {
          product.eff = "-"
          product.soh = "-"
          product.avail = "-"
          return product
        }

        // Extract branch stock from product if possible
        let eff = parseInt(
          (product.stock_by_location &&
            product.stock_by_location[selectedBranch] &&
            product.stock_by_location[selectedBranch].effective) ||
            0
        )
        let soh = parseInt(
          (product.stock_by_location &&
            product.stock_by_location[selectedBranch] &&
            product.stock_by_location[selectedBranch].soh) ||
            0
        )

        // Math to determine values to show
        product.eff = eff < 0 ? 0 : eff
        product.soh = soh < 0 ? 0 : soh
        product.avail = Math.min(product.eff, product.soh)

        return product
      }),
    [searchResults, pagesLoaded, selectedBranch]
  )

  return (
    <StyledContainter>
      <Input
        type="text"
        placeholder="Search by Title or SKU"
        value={searchTerm}
        onChange={handleChange}
      />
      {resultsToRender.length == 0 ? (
        <NoItems>
          <div>There are no products matching your search criteria</div>
        </NoItems>
      ) : (
        <Table
          headings={["Product", "Size", "SKU", "SOH", "Avail.", "Price Incl."]}
          values={resultsToRender || []}
        />
      )}
      {searchResults.length > resultsToRender.length && (
        <StyledButton
          label="Load More"
          onClick={() => setPagesLoaded((prev) => prev + 1)}
        />
      )}
    </StyledContainter>
  )
}

// ==========
// 	 STYLES
// ==========
const StyledContainter = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  div.error-text {
    text-align: center;
    margin: 10px 0;
    color: #fd3237;
  }
`
const NoItems = styled.div`
  padding: 30px;
  margin: 30px;
  border-radius: 10px;
  border: 2px solid #1a293c;
  background: #f9f9f9;
  text-align: center;
`
const StyledButton = styled(Button)`
  padding: 20px;
  margin: 40px auto;
  div {
    font-size: 24px;
  }
`
const Input = styled.input`
  margin: 20px auto;
  width: 720px;
  padding: 10px;
  border-radius: 3px;
  border-width: 1px;
  border-style: solid;
  border-color: #223247;
  background-color: #dee3e8;
  font-size: 18px;
`

export default Flatlist

const FLATLIST_PRODUCTS = graphql`
  {
    allProductFlatlist {
      nodes {
        databaseId
        price
        sku
        size
        title
        stock_by_location {
          bel {
            effective
            soh
          }
          bok {
            effective
            soh
          }
          blo {
            effective
            soh
          }
          cap {
            effective
            soh
          }
          dur {
            effective
            soh
          }
          geo {
            effective
            soh
          }
          mid {
            effective
            soh
          }
          nel {
            effective
            soh
          }
          pol {
            effective
            soh
          }
          por {
            effective
            soh
          }
          pre {
            effective
            soh
          }
          ran {
            effective
            soh
          }
        }
      }
    }
  }
`
