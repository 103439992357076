import React from "react"
import Layout from "../zzz/layout"
import Flatlist from "../components/Flatlist/flatlist"
import { isInStoreFrontend } from "../services/auth"

// ======================
// 	🧱🧱 COMPONENT 🧱🧱
// ======================

const FlatlistPage = ({ location }) => {
  return (
    <Layout location={location}>{isInStoreFrontend() && <Flatlist />}</Layout>
  )
}

export default FlatlistPage
